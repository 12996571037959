import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
ReactGA.initialize("G-BBJ5R8BQVS");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={<div className="loadingPadding">Loading...</div>}>
        <App />
      </Suspense>
    </React.StrictMode>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
