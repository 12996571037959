import { LOADING } from "../Selectors";

export const loaderReducer = (state = false, actions) => {
  switch (actions.type) {
    case LOADING:
      return (state = actions.payload);
    default:
      return state;
  }
};
