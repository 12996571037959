import {
  SEARCH_OPTIONS,
  SEARCH_DATA,
  CATEGORY_ID,
  CHECKED_FILTER,
  SEARCH_TERM,
  FAVORITE_CLICKED,
  LIKE_CLICKED,
  FILTERED_ADS,
  CLEAR_SELECTION,
} from "../Selectors";

export const storeSearchFilterListReducer = (state = "", actions) => {
  switch (actions.type) {
    case SEARCH_OPTIONS:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeSearchAdsDataReducer = (state = "", actions) => {
  switch (actions.type) {
    case SEARCH_DATA:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const searchCategorySubCategoryIdReducer = (state = "", actions) => {
  switch (actions.type) {
    case CATEGORY_ID:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeCheckedFilterReducer = (state = "", actions) => {
  switch (actions.type) {
    case CHECKED_FILTER:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeSearchItemReducer = (state = "", actions) => {
  switch (actions.type) {
    case SEARCH_TERM:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const favoriteClickedReducer = (state = false, actions) => {
  switch (actions.type) {
    case FAVORITE_CLICKED:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const likeClickedReducer = (state = false, actions) => {
  switch (actions.type) {
    case LIKE_CLICKED:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const filteredAdsReducer = (state = "", actions) => {
  switch (actions.type) {
    case FILTERED_ADS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const clearSelectionReducer = (state = false, actions) => {
  switch (actions.type) {
    case CLEAR_SELECTION:
      return (state = actions.payload);
    default:
      return state;
  }
};
