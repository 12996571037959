import {
  BOOSTED_DATE,
  BOOST_AD,
  BOOST_AD_CONTENT,
  FEATURED_ADS,
} from "../Selectors";

export const boostAdDetailsReducer = (state = "", actions) => {
  switch (actions.type) {
    case BOOST_AD:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const boostAdContentReducer = (state = "", actions) => {
  switch (actions.type) {
    case BOOST_AD_CONTENT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const storeFeaturedAdsReducer = (state = "", actions) => {
  switch (actions.type) {
    case FEATURED_ADS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const boostedAdDateReducer = (state = "", actions) => {
  switch (actions.type) {
    case BOOSTED_DATE:
      return (state = actions.payload);
    default:
      return state;
  }
};
