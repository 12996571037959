import {
  POST_AD_CLICKED,
  SHOW_SERVICE,
  SHOW_PRODUCT,
  SUB_CATEGORIES,
  PRODUCT_LIST,
  SERVICE_LIST,
  RENTAL_DURATION,
  PARENT_CATEGORY,
  RESTORE_ATTRIBUTE,
  PRODUCT_TYPE,
  SERVICE_TYPE,
  AD_TITLE,
  AD_TITLE_LABEL,
  ERRORS,
  AD_SCREEN,
  CHECK_FORM_DATA,
  DISCARD_FORM_DATA,
  ACTIVE_KEY,
  SUB_CAT_ID,
  PLACEHOLDER,
  PRIVACY_ENABLED,
} from "../Selectors";

export const postAdReducer = (state = false, actions) => {
  switch (actions.type) {
    case POST_AD_CLICKED:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showServiceFormReducer = (state = false, actions) => {
  switch (actions.type) {
    case SHOW_SERVICE:
      return (state = actions.payload);
    case SHOW_PRODUCT:
      return (state = false);
    default:
      return state;
  }
};

export const subCategoriesReducer = (state = "", actions) => {
  switch (actions.type) {
    case SUB_CATEGORIES:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const productCategoryReducer = (state = "", actions) => {
  switch (actions.type) {
    case PRODUCT_LIST:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const serviceCategoryReducer = (state = "", actions) => {
  switch (actions.type) {
    case SERVICE_LIST:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const rentalDurationReducer = (state = "", actions) => {
  switch (actions.type) {
    case RENTAL_DURATION:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const selectedParentCategoryReducer = (state = "", actions) => {
  switch (actions.type) {
    case PARENT_CATEGORY:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const restoreAttributeRecordReducer = (state = "", actions) => {
  switch (actions.type) {
    case RESTORE_ATTRIBUTE:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const productTypeReducer = (state = "", actions) => {
  switch (actions.type) {
    case PRODUCT_TYPE:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const serviceTypeReducer = (state = "", actions) => {
  switch (actions.type) {
    case SERVICE_TYPE:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const postAdTitleReducer = (state = "", actions) => {
  switch (actions.type) {
    case AD_TITLE:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const adTitleLabelReducer = (state = "", actions) => {
  switch (actions.type) {
    case AD_TITLE_LABEL:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const errorHandlingReducer = (state = [], actions) => {
  switch (actions.type) {
    case ERRORS:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const adScreenReducer = (state = false, actions) => {
  switch (actions.type) {
    case AD_SCREEN:
      return (state = true);

    default:
      return state;
  }
};

export const checkFormDataPresentReducer = (state = false, actions) => {
  switch (actions.type) {
    case CHECK_FORM_DATA:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const discardFormDataReducer = (state = false, actions) => {
  switch (actions.type) {
    case DISCARD_FORM_DATA:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const changeActiveKeyReducer = (state = "first", actions) => {
  switch (actions.type) {
    case ACTIVE_KEY:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeSubCategoryIDReducer = (state = "", actions) => {
  switch (actions.type) {
    case SUB_CAT_ID:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const subCatPlaceholderReducer = (state = "no_data", actions) => {
  switch (actions.type) {
    case PLACEHOLDER:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const privacyEnabledReducer = (state = "", actions) => {
  switch (actions.type) {
    case PRIVACY_ENABLED:
      return (state = actions.payload);

    default:
      return state;
  }
};
