import {
  CITY_LIST,
  CITY,
  RETRIEVE_CITY_DETAILS,
  CITY_POPUP,
  MAJOR_CITIES,
} from "../Selectors";

export const majorCitiesReducer = (state = "", actions) => {
  switch (actions.type) {
    case MAJOR_CITIES:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showCityPopupReducer = (state = false, actions) => {
  switch (actions.type) {
    case CITY_POPUP:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const cityReducer = (state = "", actions) => {
  switch (actions.type) {
    case CITY_LIST:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const selectedCityReducer = (state = "", actions) => {
  switch (actions.type) {
    case CITY:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const retrieveCityDetailsReducer = (state = "", actions) => {
  switch (actions.type) {
    case RETRIEVE_CITY_DETAILS:
      return (state = actions.payload);
    default:
      return state;
  }
};
