import { CHANNEL_LIST, CHANNEL, CHANNEL_MESSAGE, CHANNEL_FILTER,CHANNEL_FILE, MESSAGE_DATA, JOIN_CHAT, MESSAGE_LIVE,MESSAGE_Index, MESSAGE_Upload, NOTIFICATIONMESSAGE, STATUSCODE, ADID  } from "../Selectors";

export const channelListReducer = (state = [], actions) => {
  switch (actions.type) {
    case CHANNEL_LIST:
      return (state = actions.payload || []);
    default:
      return state;
  }
};

export const channelReducer = (state = false, actions) => {
    switch (actions.type) {
      case CHANNEL:
        return (state = actions.payload);
      default:
        return state;
    }
};

export const channelMessageReducer = (state = false, actions) => {
    switch (actions.type) {
      case CHANNEL_MESSAGE:
        return (state = actions.payload);
      default:
        return state;
    }
};

export const channelFilterReducer = (state = false, actions) => {
    switch (actions.type) {
      case CHANNEL_FILTER:
        return (state = actions.payload);
      default:
        return state;
    }
};

export const channelFile = (state = false, actions) => {
  switch (actions.type) {
    case CHANNEL_FILE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const MessageChat = (state = false, actions) => {
  switch (actions.type) {
    case MESSAGE_DATA:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const JoinChatReducer = (state = false, actions) => {
  switch (actions.type) {
    case JOIN_CHAT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const MessageliveReducer = (state = false, actions) => {
  switch (actions.type) {
    case MESSAGE_LIVE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const MessageindexReducer = (state = false, actions) => {
  switch (actions.type) {
    case MESSAGE_Index:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const MessageuploadReducer = (state = false, actions) => {
  switch (actions.type) {
    case MESSAGE_Upload:
      return (state = actions.payload);
    default:
      return state;
  }
};


export const notificationReducer = (state = false, actions) => {
  switch (actions.type) {
    case NOTIFICATIONMESSAGE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const statusReducer = (state = "", actions) => {
  switch (actions.type) {
    case STATUSCODE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const adIdReducer = (state = "", actions) => {
  switch (actions.type) {
    case ADID:
      return (state = actions.payload);
    default:
      return state;
  }
};