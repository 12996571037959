export const INSTALLATION_COUNT = "INSTALLATION_COUNT";
export const CITY_POPUP = "CITY_POPUP";
export const MAJOR_CITIES = "MAJOR_CITIES";
export const DEVICE = "DEVICE";
export const POST_AD_CLICKED = "POST_AD_CLICKED";
export const SHOW_SERVICE = "SHOW_SERVICE";
export const SHOW_PRODUCT = "SHOW_PRODUCT";
export const SUB_CATEGORIES = "SUB_CATEGORIES";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const SERVICE_LIST = "SERVICE_LIST";
export const RENTAL_DURATION = "RENTAL_DURATION";
export const LOGGED_IN = "LOGGED_IN";
export const USER_DETAILS = " USER_DETAILS ";
export const CITY_LIST = "CITY_LIST";
export const PARENT_CATEGORY = "PARENT_CATEGORY";
export const RESTORE_ATTRIBUTE = "RESTORE_ATTRIBUTE";
export const PRODUCT_TYPE = "PRODUCT_TYPE";
export const SERVICE_TYPE = "SERVICE_TYPE";
export const AD_TITLE = "AD_TITLE";
export const AD_TITLE_LABEL = "AD_TITLE_LABEL";
export const ERRORS = "ERRORS";
export const AD_SCREEN = "AD_SCREEN";
export const CHECK_FORM_DATA = "CHECK_FORM_DATA";
export const DISCARD_FORM_DATA = "DISCARD_FORM_DATA";
export const BANNER_LIST_DATA = "BANNER_LIST_DATA";
export const PRODUCT_FLYER = "PRODUCT_FLYER";
export const SERVICE_FLYER = "SERVICE_FLYER";
export const POPULAR_ADS = "POPULAR_ADS";
export const ADS_COUNT = "ADS_COUNT";
export const SHOW_AD_DETAILS = "SHOW_AD_DETAILS";
export const RESET_MODAL = "RESET_MODAL";
export const EDIT_AD_DETAILS = "EDIT_AD_DETAILS";
export const SEARCH_OPTIONS = "SEARCH_OPTIONS";
export const SEARCH_DATA = "SEARCH_DATA";
export const CATEGORY_ID = "CATEGORY_ID";
export const MY_DASHBOARD = "MY_DASHBOARD";
export const FANCY_PROSPERID_PAYMENT = "FANCY_PROSPERID_PAYMENT";
export const CHECKED_FILTER = "CHECKED_FILTER";
export const ACTIVE_KEY = "ACTIVE_KEY";
export const NEED_OFFER = "NEED_OFFER";
export const LOADING = "LOADING";
export const SEARCH_TERM = "SEARCH_TERM";
export const FAVORITE_CLICKED = "FAVORITE_CLICKED";
export const LIKE_CLICKED = "LIKE_CLICKED";
export const SHOW_PRODUCT_SERVICE = "SHOW_PRODUCT_SERVICE";
export const VIEW_PROFILE = "VIEW_PROFILE";
export const VIEW_ADS_DATA = "VIEW_ADS_DATA";
export const PROFILE_LIST_DATA = "PROFILE_LIST_DATA";
export const AD_BANNER = "AD_BANNER";
export const EDIT_BANNER = "EDIT_BANNER";
export const MY_BANNERS = "MY_BANNERS";
export const REPORT = "REPORT";
export const DIVERT_TO_APP = "DIVERT_TO_APP";
export const SHOW_MENU = "SHOW_MENU";
export const FROM_VIEW_PROFILE = "FROM_VIEW_PROFILE";
export const INACTIVE = "INACTIVE";
export const SHOW_IMAGE_MODAL = "SHOW_IMAGE_MODAL";
export const CITY = "CITY";
export const BANNER_SPINNER = "BANNER_SPINNER";
export const FLYER_SPINNER = "FLYER_SPINNER";
export const DEMO_WEBPAGE = "DEMO_WEBPAGE";
export const NO_ADS_TEXT = "NO_ADS_TEXT";
export const APPROVED_BANNER_ID = "APPROVED_BANNER_ID";
export const SUB_CAT_ID = "SUB_CAT_ID";
export const SORT_VALUE = "SORT_VALUE";
export const CAT_NAME = "CAT_NAME";
export const NO_INTERNET = "NO_INTERNET";
export const PROSPER_ADS = "PROSPER_ADS";
export const HISTORY_COUNT = "HISTORY_COUNT";
export const REQUEST_FLYER = "REQUEST_FLYER";
export const ERROR_404 = "ERROR_404";
export const FILTERED_ADS = "FILTERED_ADS";
export const RETRIEVE_CITY_DETAILS = "RETRIEVE_CITY_DETAILS";
export const META_TITLE = "META_TITLE";
export const META_DESCRIPTION = "META_DESCRIPTION";
export const CLEAR_SELECTION = "CLEAR_SELECTION";
export const PLACEHOLDER = "PLACEHOLDER";
export const PRIVACY_ENABLED = "PRIVACY_ENABLED";
export const FOOTER_BANNER = "FOOTER_BANNER";
export const BOOST_AD = "BOOST_AD";
export const BOOST_AD_CONTENT = "BOOST_AD_CONTENT";
export const FEATURED_ADS = "FEATURED_ADS";
export const BOOSTED_DATE = "BOOSTED_DATE";
export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const SCROLL_POSITION = "SCROLL_POSITION";
export const META_KEYWORDS = "META_KEYWORDS";

export const CHANNEL_LIST = "CHANNEL_LIST";
export const CHANNEL = "CHANNEL";
export const CHANNEL_MESSAGE = "CHANNEL_MESSAGE";
export const CHANNEL_FILTER = "CHANNEL_FILTER";
export const CHANNEL_FILE = "CHANNEL_FILE";
export const MESSAGE_DATA = "MESSAGE_DATA";
export const JOIN_CHAT = "JOIN_CHAT";
export const MESSAGE_LIVE = "MESSAGE_LIVE";
export const MESSAGE_Index = "MESSAGE_Index";
export const MESSAGE_Upload = "MESSAGE_Upload";
export const NOTIFICATIONMESSAGE = "NOTIFICATIONMESSAGE";
export const STATUSCODE = "STATUSCODE";
export const ADID = "ADID";