import {
  DEVICE,
  REQUIREMENT_AD,
  NEED_OFFER,
  SHOW_PRODUCT_SERVICE,
  REPORT,
  DIVERT_TO_APP,
  SHOW_MENU,
  FROM_VIEW_PROFILE,
  INACTIVE,
  SHOW_IMAGE_MODAL,
  FLYER_SPINNER,
  BANNER_SPINNER,
  DEMO_WEBPAGE,
  NO_ADS_TEXT,
  APPROVED_BANNER_ID,
  SORT_VALUE,
  CAT_NAME,
  NO_INTERNET,
  PROSPER_ADS,
  HISTORY_COUNT,
  ERROR_404,
  META_TITLE,
  META_DESCRIPTION,
  FOOTER_BANNER,
  SCROLL_POSITION,
  META_KEYWORDS,
  INSTALLATION_COUNT,
} from "../Selectors";

export const installationCountReducer = (state = "", actions) => {
  switch (actions.type) {
    case INSTALLATION_COUNT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const renderingDeviceReducer = (state = "laptop", actions) => {
  switch (actions.type) {
    case DEVICE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const toggleNeedOrOfferReducer = (state = true, actions) => {
  switch (actions.type) {
    case NEED_OFFER:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showProductServiceDropdownReducer = (state = false, actions) => {
  switch (actions.type) {
    case SHOW_PRODUCT_SERVICE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const reportUsPageReducer = (state = false, actions) => {
  switch (actions.type) {
    case REPORT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const divertToAppReducer = (state = false, actions) => {
  switch (actions.type) {
    case DIVERT_TO_APP:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showMenuReducer = (state = "", actions) => {
  switch (actions.type) {
    case SHOW_MENU:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const fromProfilePageReducer = (state = "home", actions) => {
  switch (actions.type) {
    case FROM_VIEW_PROFILE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const statusInactiveReducer = (state = false, actions) => {
  switch (actions.type) {
    case INACTIVE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showImageModalReducer = (state = false, actions) => {
  switch (actions.type) {
    case SHOW_IMAGE_MODAL:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const bannerSpinnerReducer = (state = false, actions) => {
  switch (actions.type) {
    case BANNER_SPINNER:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const flyerSpinnerReducer = (state = false, actions) => {
  switch (actions.type) {
    case FLYER_SPINNER:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showDemoWebpageReducer = (state = false, actions) => {
  switch (actions.type) {
    case DEMO_WEBPAGE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const noAdsScreenTextReducer = (state = false, actions) => {
  switch (actions.type) {
    case NO_ADS_TEXT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const approvedBannerIdReducer = (state = "", actions) => {
  switch (actions.type) {
    case APPROVED_BANNER_ID:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const storeSortNameReducer = (state = "", actions) => {
  switch (actions.type) {
    case SORT_VALUE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const filteredCategoryNameReducer = (state = "", actions) => {
  switch (actions.type) {
    case CAT_NAME:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const noInternetFallbackUIReducer = (state = false, actions) => {
  switch (actions.type) {
    case NO_INTERNET:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const prosperAdsSuggesstionsReducer = (state = "", actions) => {
  switch (actions.type) {
    case PROSPER_ADS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const historyCountReducer = (state = 0, actions) => {
  switch (actions.type) {
    case HISTORY_COUNT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const error404Reducer = (state = false, actions) => {
  switch (actions.type) {
    case ERROR_404:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const metaTitleReducer = (state = "", actions) => {
  switch (actions.type) {
    case META_TITLE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const metaDescriptionReducer = (state = "", actions) => {
  switch (actions.type) {
    case META_DESCRIPTION:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const metaKeywordsReducer = (state = "", actions) => {
  switch (actions.type) {
    case META_KEYWORDS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const showFooterBannerReducer = (state = false, actions) => {
  switch (actions.type) {
    case FOOTER_BANNER:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const scrollPositionReducer = (state = 0, actions) => {
  switch (actions.type) {
    case SCROLL_POSITION:
      return (state = actions.payload);
    default:
      return state;
  }
};
