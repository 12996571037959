import { createStore } from "redux";
import allReducers from "./index";

let store = createStore(
  allReducers,
  process.env.NODE_ENV === "production"
    ? ""
    : window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
);
// store.subscribe(() => {console.log(store.getState())})

export default store;
