import { LOGGED_IN, USER_DETAILS, RESET_MODAL } from "../Selectors";

// export const loggedInReducer = (state=false, actions) => {
//   switch (actions.type) {
//     case LOGGED_IN:
//       return !state;
//     default: return state;
//   }
// }

export const loggedInReducer = (state = false, actions) => {
  switch (actions.type) {
    case LOGGED_IN:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const userDetailsReducer = (state = "", actions) => {
  switch (actions.type) {
    case USER_DETAILS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const resetModalReducer = (state = true, actions) => {
  switch (actions.type) {
    case RESET_MODAL:
      return (state = actions.payload);
    default:
      return state;
  }
};
