import {
  postAdReducer,
  showServiceFormReducer,
  subCategoriesReducer,
  productCategoryReducer,
  serviceCategoryReducer,
  rentalDurationReducer,
  selectedParentCategoryReducer,
  restoreAttributeRecordReducer,
  productTypeReducer,
  serviceTypeReducer,
  postAdTitleReducer,
  adTitleLabelReducer,
  errorHandlingReducer,
  adScreenReducer,
  discardFormDataReducer,
  checkFormDataPresentReducer,
  changeActiveKeyReducer,
  storeSubCategoryIDReducer,
  subCatPlaceholderReducer,
  privacyEnabledReducer,
} from "./Reducers/postAdReducer";
import {
  loggedInReducer,
  userDetailsReducer,
  resetModalReducer,
} from "./Reducers/loginReducer";
import {
  cityReducer,
  majorCitiesReducer,
  retrieveCityDetailsReducer,
  selectedCityReducer,
  showCityPopupReducer,
} from "./Reducers/cityReducer";
import { combineReducers } from "redux";
import {
  storeBannerListReducer,
  storeProductFlyerReducer,
  storeServiceFlyerReducer,
  storePopularAdsReducer,
  storeMyBannerListReducer,
  requestFlyerClickedReducer,
  notificationCountReducer,
} from "./Reducers/homeReducer";

import {
  adsCountReducer,
  adDetailsReducer,
  editAdDetailsReducer,
  goToDashboardReducer,
  viewProfileDataReducer,
  storeViewAdsDataReducer,
  storeProfileListReducer,
  bannerAdButtonClickReducer,
  editBannerDetailsReducer,
} from "./Reducers/dashboardReducer";

import {
  storeSearchFilterListReducer,
  storeSearchAdsDataReducer,
  searchCategorySubCategoryIdReducer,
  storeCheckedFilterReducer,
  storeSearchItemReducer,
  favoriteClickedReducer,
  likeClickedReducer,
  filteredAdsReducer,
  clearSelectionReducer,
} from "./Reducers/FilterReducer";
import { fancyProsperPaymentReducer } from "./Reducers/razorpayReducer";
import {
  toggleNeedOrOfferReducer,
  showProductServiceDropdownReducer,
  reportUsPageReducer,
  divertToAppReducer,
  showMenuReducer,
  fromProfilePageReducer,
  statusInactiveReducer,
  showImageModalReducer,
  bannerSpinnerReducer,
  flyerSpinnerReducer,
  showDemoWebpageReducer,
  noAdsScreenTextReducer,
  approvedBannerIdReducer,
  storeSortNameReducer,
  filteredCategoryNameReducer,
  noInternetFallbackUIReducer,
  prosperAdsSuggesstionsReducer,
  historyCountReducer,
  error404Reducer,
  metaTitleReducer,
  metaDescriptionReducer,
  showFooterBannerReducer,
  scrollPositionReducer,
  metaKeywordsReducer,
  renderingDeviceReducer,
  installationCountReducer,
} from "./Reducers/commonReducer";
import { loaderReducer } from "./Reducers/loaderReducer";
import {
  boostAdDetailsReducer,
  boostAdContentReducer,
  storeFeaturedAdsReducer,
  boostedAdDateReducer,
} from "./Reducers/boostAdReducer";

import { 
  channelListReducer,
  channelReducer,
  channelMessageReducer,
  channelFilterReducer, 
  MessageChat,
  JoinChat,
  JoinChatReducer,
  MessageliveReducer,
  MessageindexReducer,
  MessageuploadReducer,
  notificationReducer,
  statusReducer,
  adIdReducer
} from "./Reducers/chatReducer";

const allReducers = combineReducers({
  major_city: majorCitiesReducer,
  cityPopup: showCityPopupReducer,
  device: renderingDeviceReducer,
  adPostClicked: postAdReducer,
  showService: showServiceFormReducer,
  loggedIn: loggedInReducer,
  userDetails: userDetailsReducer,
  cityList: cityReducer,
  subCategories: subCategoriesReducer,
  productCategories: productCategoryReducer,
  serviceCategories: serviceCategoryReducer,
  rentalDuration: rentalDurationReducer,
  parentCategory: selectedParentCategoryReducer,
  attributeRecord: restoreAttributeRecordReducer,
  productType: productTypeReducer,
  serviceType: serviceTypeReducer,
  adTitle: postAdTitleReducer,
  ad_title_label: adTitleLabelReducer,
  errorHandling: errorHandlingReducer,
  goToAdScreen: adScreenReducer,
  dataPresent: checkFormDataPresentReducer,
  discardFormData: discardFormDataReducer,
  bannerList: storeBannerListReducer,
  productFlyer: storeProductFlyerReducer,
  serviceFlyer: storeServiceFlyerReducer,
  popularAds: storePopularAdsReducer,
  adsCount: adsCountReducer,
  showAdDetails: adDetailsReducer,
  resetModal: resetModalReducer,
  adDetailsToEdit: editAdDetailsReducer,
  filterOptions: storeSearchFilterListReducer,
  adsData: storeSearchAdsDataReducer,
  categorySubCategoryId: searchCategorySubCategoryIdReducer,
  goToDashboard: goToDashboardReducer,
  fancyProsperIDPayment: fancyProsperPaymentReducer,
  checkedFilterOptions: storeCheckedFilterReducer,
  activeKey: changeActiveKeyReducer,
  needOrOffer: toggleNeedOrOfferReducer,
  loading: loaderReducer,
  searchTerm: storeSearchItemReducer,
  favoriteClick: favoriteClickedReducer,
  likeClick: likeClickedReducer,
  showProductService: showProductServiceDropdownReducer,
  viewProfileData: viewProfileDataReducer,
  viewAdsData: storeViewAdsDataReducer,
  profileDetails: storeProfileListReducer,
  showBannerAd: bannerAdButtonClickReducer,
  editBannerDetails: editBannerDetailsReducer,
  myBannerList: storeMyBannerListReducer,
  reportUs: reportUsPageReducer,
  divertToApp: divertToAppReducer,
  showMenu: showMenuReducer,
  fromViewProfile: fromProfilePageReducer,
  isInactive: statusInactiveReducer,
  showImageModal: showImageModalReducer,
  selectedCity: selectedCityReducer,
  bannerSpinner: bannerSpinnerReducer,
  flyerSpinner: flyerSpinnerReducer,
  showDemoWebpage: showDemoWebpageReducer,
  noAdsScreenFrom: noAdsScreenTextReducer,
  approveBannerId: approvedBannerIdReducer,
  subCatID: storeSubCategoryIDReducer,
  sortValue: storeSortNameReducer,
  categoryName: filteredCategoryNameReducer,
  noInternet: noInternetFallbackUIReducer,
  prosperAdsSuggesstions: prosperAdsSuggesstionsReducer,
  historyCount: historyCountReducer,
  requestFlyerClicked: requestFlyerClickedReducer,
  error404: error404Reducer,
  filteredAds: filteredAdsReducer,
  retrieveCityDetails: retrieveCityDetailsReducer,
  metaTitle: metaTitleReducer,
  metaDescription: metaDescriptionReducer,
  metaKeywords: metaKeywordsReducer,
  clearSelection: clearSelectionReducer,
  subCatPlaceholder: subCatPlaceholderReducer,
  privacyEnabled: privacyEnabledReducer,
  showFooterBanner: showFooterBannerReducer,
  boostAdDetails: boostAdDetailsReducer,
  boostAdContent: boostAdContentReducer,
  featuredAds: storeFeaturedAdsReducer,
  boostedDate: boostedAdDateReducer,
  notificationCount: notificationCountReducer,
  scrollPosition: scrollPositionReducer,
  installationCount: installationCountReducer,
  channelList: channelListReducer,
  channel: channelReducer,
  channelMessage: channelMessageReducer,
  channelFilter: channelFilterReducer ,
  MessageChat:MessageChat,
  JoinChat:JoinChatReducer,
  Messagelive:MessageliveReducer,
  Messageindex:MessageindexReducer,
  MessageUpload:MessageuploadReducer,
  notificationUpdate:notificationReducer,
  statusChanges:statusReducer,
  adIdGet:adIdReducer
});

export default allReducers;
