import {
  ADS_COUNT,
  SHOW_AD_DETAILS,
  EDIT_AD_DETAILS,
  MY_DASHBOARD,
  VIEW_PROFILE,
  VIEW_ADS_DATA,
  PROFILE_LIST_DATA,
  AD_BANNER,
  EDIT_BANNER,
} from "../Selectors";

export const adsCountReducer = (state = 0, actions) => {
  switch (actions.type) {
    case ADS_COUNT:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const adDetailsReducer = (state = false, actions) => {
  switch (actions.type) {
    case SHOW_AD_DETAILS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const editAdDetailsReducer = (state = "", actions) => {
  switch (actions.type) {
    case EDIT_AD_DETAILS:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const goToDashboardReducer = (state = false, actions) => {
  switch (actions.type) {
    case MY_DASHBOARD:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const viewProfileDataReducer = (state = false, actions) => {
  switch (actions.type) {
    case VIEW_PROFILE:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const storeViewAdsDataReducer = (state = "", actions) => {
  switch (actions.type) {
    case VIEW_ADS_DATA:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeProfileListReducer = (state = "", actions) => {
  switch (actions.type) {
    case PROFILE_LIST_DATA:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const bannerAdButtonClickReducer = (state = false, actions) => {
  switch (actions.type) {
    case AD_BANNER:
      return (state = actions.payload);
    default:
      return state;
  }
};

export const editBannerDetailsReducer = (state = "", actions) => {
  switch (actions.type) {
    case EDIT_BANNER:
      return (state = actions.payload);
    default:
      return state;
  }
};
