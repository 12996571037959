import {
  BANNER_LIST_DATA,
  PRODUCT_FLYER,
  SERVICE_FLYER,
  POPULAR_ADS,
  MY_BANNERS,
  REQUEST_FLYER,
  NOTIFICATION_COUNT,
} from "../Selectors";

export const storeBannerListReducer = (state = "", actions) => {
  switch (actions.type) {
    case BANNER_LIST_DATA:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeProductFlyerReducer = (state = "", actions) => {
  switch (actions.type) {
    case PRODUCT_FLYER:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeServiceFlyerReducer = (state = "", actions) => {
  switch (actions.type) {
    case SERVICE_FLYER:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storePopularAdsReducer = (state = "", actions) => {
  switch (actions.type) {
    case POPULAR_ADS:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const storeMyBannerListReducer = (state = "", actions) => {
  switch (actions.type) {
    case MY_BANNERS:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const requestFlyerClickedReducer = (state = false, actions) => {
  switch (actions.type) {
    case REQUEST_FLYER:
      return (state = actions.payload);

    default:
      return state;
  }
};

export const notificationCountReducer = (state = "", actions) => {
  switch (actions.type) {
    case NOTIFICATION_COUNT:
      return (state = actions.payload);

    default:
      return state;
  }
};
