import { FANCY_PROSPERID_PAYMENT } from "../Selectors";

export const fancyProsperPaymentReducer = (state = "", actions) => {
  switch (actions.type) {
    case FANCY_PROSPERID_PAYMENT:
      return (state = actions.payload);
    default:
      return state;
  }
};
